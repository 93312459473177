button.ant-btn-primary, .tabsShort .ant-tabs-tab{
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
  border: none;
  height: 50px;
  padding: 0 40px !important;
  font-family: Inter;
  font-size: 16px;
  border-radius: 10px;
  font-weight: 600;
  color: #fff !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.col-sm-12.submit{
  text-align: right;
}
.ant-input-outlined.ant-input-password, input#login_email, .row.loginBox .col-sm-7 input {
  height: 50px;
  border: 1.11px solid #6C7275;
  border-radius: 10px;
  font-family: Inter;
}
.ant-input-outlined.ant-input-password input::placeholder, .row.loginBox .col-sm-7 input::placeholder, .ant-picker.ant-picker-range.ant-picker-outlined input::placeholder, .fade.AddModal.modal.show .row.noPad .inot input::placeholder, .ant-select-selection-search-input::placeholder{
  font-weight: 600;
  color: #9D9D9D;
}
.col-sm-12.submit button.ant-btn-primary:hover{
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
  transform: scale(1.02);
  transition: all ease-in 300ms;
}
.row.noPad {
  margin: 0 !important;
  padding-top: 30px;
}
.row.loginBox .col-sm-7{
  box-shadow: 0px 17px 25px 0px #0E6FC838;
  padding: 80px 50px;
  border-radius: 20px 150px;
}
.container-fluid.modaal {
  padding: 50px 20px;
}
.container-fluid.modaal .ant-upload.ant-upload-select {
  width: 100% !important;
  /* padding-top: 56px; */
}
.ant-upload-list-item {
  margin-top: 0 !important;
  width: 100% !important;
}
.ant-upload-list-item-container {
  height: 50px !important;
  margin-top: 10px;
  border: 1px solid #6C7275;
  border-radius: 10px;
  padding: 0 12px;
  display: flex;
  align-items: center;
}
.container-fluid.modaal .ant-upload.ant-upload-select button{
  width: 100%;
  height: 98px;
  border: 1px solid #6C7275 !important;
  border-radius: 10px;
}
.container-fluid.modaal .ant-upload.ant-upload-select button:hover{
  color: #000 !important;
  border-color: #000 !important;
}
.ant-popover.ant-color-picker.ant-popover-placement-bottomLeft {
  z-index: 99999 !important;
}
.container-fluid.modaal h2{
  font-family: Poppins;
  font-size: 30px;
  color: #000;
  text-transform: capitalize;
  font-weight: 600;
}
.col-sm-10.buttonDispaly .row{
  margin: 0;
}
.ant-tabs-left >.ant-tabs-nav .ant-tabs-tab, .ant-tabs-right >.ant-tabs-nav .ant-tabs-tab, .ant-tabs-left >div>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-right >div>.ant-tabs-nav .ant-tabs-tab{
  padding: 0 12px;
  margin: 0;
}
.col-sm-10.buttonDispaly .col-sm-12{
  padding-left: 0;
}
.col-sm-10.buttonDispaly .container-fluid {
  padding: 0;
}
.container-fluid.modaal h3{
  color: #000;
  text-transform: capitalize;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
}
.col-sm-3.svgHeader{
  text-align: right;
  position: relative;
  margin-top: -65px;
}
.col-sm-12.forrrms{
  padding: 0 24px !important;
}
.col-sm-3.svgHeader img{
  height: 250px;
  position: absolute;
  top: -100px !important;
  right: 0%;
}
.row.loginBox{
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.col-sm-12.submit b{
  cursor: pointer;
}
.row.noPad .col-sm-4 button.ant-btn-primary{
  margin-bottom: 24px;
  width: 96% !important;
  padding: 0 !important;
}
.col-sm-12.submit p{
  margin-bottom: 24px !important;
}
.ant-tabs-top >.ant-tabs-nav::before, .ant-tabs-bottom >.ant-tabs-nav::before, .ant-tabs-top >div>.ant-tabs-nav::before, .ant-tabs-bottom >div>.ant-tabs-nav::before{
  border-bottom: unset !important;
}
input#login_password, input#login_confirmPassword{
  height: 40px !important;
  border: none !important;
}
.row.topMargin{
  justify-content: flex-end;
}
.col-sm-12.dmTabs .ant-tabs-tab, .row.boddyTab .col-sm-12 .ant-tabs-tab {
  margin-bottom: 10px !important;
}
.row.boddyTab .col-sm-12 {
  box-shadow: 0px 4px 25px 0px #0000001A;
  margin-top: 40px;
  padding: 20px 20px 40px;
  border-radius: 15px;
  background: #FFFFFF;
}
.col-sm-3.sidebarss .col-sm-12{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}
.col-sm-3.sidebarss{
  background: #121945;
  padding: 0;
  width: 20%;
  height: 100vh;
  overflow-y: scroll;
}
.offcanvas.offcanvas-start.show{
  background: #121945 !important;
  color: #fff;
}
.offcanvas.offcanvas-start.show svg{
  fill: #fff !important;
}
.col-sm-3.sidebarss ul{
  padding: 0;
  margin: 0;
  width: 100%;
}
.col-sm-3.sidebarss .container-fluid {
  padding-left: 0;
}
thead th:first-child{
  border-radius: 25px 0 0 25px !important;
}
thead th:last-child{
  border-radius: 0 25px 25px 0 !important;
}
thead th{
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
  color: #fff !important;
}
.assdd {
  cursor: pointer;
  text-transform: capitalize;
}
td, th{
  padding: 20px;
}
.col-sm-3.sidebarss ul a{
  color: #fff;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 20px;
}
.col-sm-3.sidebarss ul li{
  width: 100% !important;
}
.col-sm-3.sidebarss ul li:hover, .active{
  border-radius: 0 40px 40px 0;
  cursor: pointer;
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
}
 .active{
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
  border-radius: 0 40px 40px 0;
  cursor: pointer;
}
.patternName{
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
  font-size: 20px;
}
.col-sm-3.sidebarss ul li a{
  width: 100% !important;
  display: flex;
  padding: 20px 50px;
}
.col-sm-3.sidebarss .row {
  margin: 0 !important;
}
.col-sm-3.sidebarss ul li{
  margin-bottom: 20px;
}
.col-sm-9.ssidebar{
  width: 80% !important;
  height: 100vh;
  overflow-y: scroll;
}
::-webkit-scrollbar{
  display: none;
}
tbody tr {
  border-bottom: 2px solid #efefef;
}
tbody td{
  padding: 10px 20px !important;
}
.chart-wrapper {
  height: 100% !important;
  width: 100%;
  justify-content: center;
  display: flex;
}
.col-sm-12.barchart{
  padding: 40px 120px;
  margin-top: 50px;
  height: 500px !important;
}
.col-sm-12.dashBoard{
  margin: 40px 0 20px;
}
.col-sm-12.dashBoard h2{
  font-family: Poppins;
  font-weight: 700;
  font-size: 36px;
}
.ant-picker.ant-picker-range.ant-picker-outlined {
  height: 50px;
  width: 30%;
  border: 1.11px solid #6C7275;
  border-radius: 10px;
}
.row.dataBox{
  padding: 20px 100px;
  justify-content: space-evenly;
}
.row.dataBox .col-sm-3{
  border: 1px solid #121945;
  border-radius: 10px;
  width: 24%;
  text-align: center;
  padding: 25px;
}
.row.dataBox p{
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
}
.row.dataBox h3{
  font-family: Poppins;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 0;
}
.row.shirtgs{
  padding-top: 30px;
}
.row.shirtgs h1{
  font-family: Poppins;
  font-size: 26px;
  letter-spacing: normal;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}
.row.shirtgs .col-sm-12 img{
  cursor: pointer;
}
.row.filter .ant-input-outlined, .row.filter .ant-select.ant-select-outlined{
  height: 50px;
  border-radius: 10px;
  width: 100%;
  border: 1.11px solid #6C7275;

}
.ant-select-selector{
  border-radius: 10px !important;
  border: none !important; 
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
}
.fade.AddModal.modal.show .modal-dialog {
  min-width: 70% !important;
} 
.fade.AddModal.modal.show .ant-select.ant-select-outlined.ant-select-in-form-item.ant-select-single.ant-select-show-arrow, .ant-select.ant-select-outlined.ant-select-in-form-item.ant-select-single.ant-select-show-arrow{
  border: 1.1px solid #6C7275;
  height: 50px;
  border-radius: 10px;
}
.fade.AddModal.modal.show .inot input, .col-sm-12.forrrms.inot input{
  border: 1.1px solid #6C7275;
  height: 50px;
  border-radius: 10px;
}
.col-sm-12.inot{
  padding-top: 30px;
}
.ant-select-dropdown {
  z-index: 999999 !important;
}
.profile input{
  height: 50px;
  border-radius: 10px !important;
  border: 1.1px solid #6C7275 !important;
  box-shadow: none !important;
  font-family: Poppins;
}
.profile label{
  font-family: Poppins;
  font-weight: 600;
  color: #000;
  font-size: 16px !important;
}
.profile{
  padding: 50px 0; 
}
.chngPassword form{
  padding: 20px 0 50px;
}
label{
  font-weight: 600;
}
.col-sm-12.close{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.col-sm-12.close svg{
  color: #000;
  font-size: 20px;
}
.col-sm-12.fortop{
  padding-bottom: 50px;
  text-align: right;
}
.col-sm-12.fortop.submit{
  padding-bottom: 0 !important;
  padding-top: 50px;
}
.row.mobile{
  display: none !important;
}
.row.boddyTab .ant-tabs-tab.ant-tabs-tab-active{
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
  color: #fff !important;
}
.row.boddyTab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
}
.fleexWrapColorInside {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.fleexWrapColorOutsiude {
  display: flex;
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  justify-content: center;
}
.cololrnMAe {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px !important;
  font-weight: 500;
  align-items: center;
  padding: 0 15px;
  width: 83px;
  text-align: center;
}
.row.boddyTab .col-sm-12{
  box-shadow: 0px 4px 25px 0px #0000001A;
  margin-top: 40px;
  padding: 20px;
  border-radius: 15px;
  background: #FFFFFF;

}
.row.boddyTab .col-sm-12 .ant-tabs-tab:hover{
  color: #000 !important;
}
.row.boddyTab .ant-tabs-nav, .row.boddyTab .ant-tabs-nav-wrap{
  width: 100%;
}
.col-sm-2.forSvg {
  background: #FFFFFF;
  box-shadow: 0px 4px 50px 0px #0000001A;
  border-radius: 10px;
}
.imageeeSvg {
  margin-top: 40px;
  padding: 0 8px;
}
.col-sm-2.forSvg .shirtsvg {
  max-height: 500px;
  border: 1px solid #E1DDDD;
  box-shadow: 0px 4px 50px 0px #0000001A;
  margin-bottom: 10px;
  border-radius: 10px;
}
.row.boddyTab .ant-tabs-nav {
  background: #fff;
  box-shadow: none !important;
}
.swiper-wrapper .swiper-slide svg {
  height: 400px;
}
.ceeenter button{
  box-shadow: none !important;
}
.ceeenter{
  display: flex;
  justify-content: center;
  width: 100%;
}
.restart{
  background: #EFEFEF !important;
  width: auto !important;
  height: auto !important;
  padding: 10px 30px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
}
.restart img{
  width: 16px !important;
  height: 16px !important;
  margin-right: 10px;
}
.mainPattern{
  stroke: #000;
  stroke-width: 3;
  fill-rule: nonzero;
}
.bodyPattern, .slelevePattern{
  fill-rule: nonzero;
}
.xyz{
  fill: #525f67;
}
.forStroek{
  fill: none;
  stroke: #1c1a00;
  stroke-miterlimit: 10;
}
.ant-tabs .ant-tabs-ink-bar{
  background: #292D76 !important;
}
.ant-tabs-nav-list img{
  height: 150px;
}
.col-sm-10.buttonDispaly button:hover, .ant-tabs-tab:hover button{
  box-shadow: 0px 17px 25px 0px #0E6FC838;
  color: black !important;
}
.row.boddyTab .col-sm-12 .ant-tabs-tab:hover{
  color: #000 !important;
}
.row.boddyTab .col-sm-12 .ant-tabs-tab{
  background: #EFEFEF;
  padding: 13px 40px;
  border-radius: 10px;
  font-weight: 500;
}
.col-sm-10.buttonDispaly button:hover, .row.boddyTab .ant-tabs-tab:hover button{
  box-shadow: 0px 17px 25px 0px #0E6FC838;
  color: black !important;
}
.ant-tabs-nav-list {
  padding: 40px 0px 20px;
}
.row.boddyTab .col-sm-12 .ant-tabs-nav-list, .col-sm-12.dmTabs .ant-ta {
  padding: 0;
}
.ant-tabs-nav {
  background: #fff;
  box-shadow: 0px 4px 50px 0px #0000001A;
  border-radius: 10px 0px 0px 10px !important;
}
.row.shirtgs .col-sm-3 img {
  height: 300px;
  max-width: 100% !important;
}
.ant-modal-footer .ant-btn-primary{
  height: 32px !important;
  border: 1px solid #d9d9d9 !important;
  background: #fff !important;
  color: #000 !important;
  font-weight: 300;
  text-transform: capitalize !important;
  padding: 0 15px !important;
  font-size: 14px !important;
  border-radius: 7px !important;
}
.col-sm-12.dmTabs .ant-tabs-nav-list{
  padding: 0 !important;
}
.col-sm-12.dmTabs .ant-tabs-nav{
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.col-sm-10.buttonDispaly button {
  width: 100%;
  height: 200px;
  box-shadow: 0px 3.57px 22.29px 0px #0000001A;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col-sm-12.previeeww{
  /* background: #F8F8F8; */
  /* box-shadow: 0px 4px 50px 0px #0000001A; */
  border-radius: 10px;
  margin-bottom: 40px;
}

.col-sm-10.buttonDispaly{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  padding: 0;
}
.col-sm-2.forSvg{
  width: 20%;
}
.col-sm-2.forSvg .ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow {
  width: 100% !important;
  height: 50px !important;
}
.col-sm-2.forSvg span.ant-select-selection-item{
  font-weight: 600 !important;
}
.col-sm-2.forSvg .ant-select .ant-select-arrow{
  color: #000 !important;
}
.col-sm-2.forSvg .ant-select-selector {
  background: #d9d9d9 !important;
  border: 1px solid #6C7275 !important;
  border-radius: 10px !important;
}
.col-sm-10.buttonDispaly button img{
  width: auto;
  height: 150px;
}
.downLOad{
  background: #292D76 !important;
  border: 1px solid #292D76 !important;
  color: white !important;
  width: 100%;
  border-radius: 10px !important;
  height: 50px;
  margin-top: 10px;
  font-weight: 600;
  font-family: Poppins;
}
.fleexWrapColor{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
nav.paginationMain.pagination {
  justify-content: center;
  margin-top: 35px;
}
.ant-pagination .ant-pagination-item-active{
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%) !important;
  color: #fff;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container, .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container, .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container, .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container {
  width: 100px;
  height: 100px !important; 
}
.row.loginBox .col-sm-5 {
  box-shadow: 0px 17px 25px 0px #0E6FC838;
  padding: 80px 50px;
  border-radius: 20px 150px;
  background: #121945;
}
.loginForm .col-sm-12.submit p {
  margin-bottom: 24px !important;
  color: #fff;
}
.lottie-dash div{

height: 300px !important;
}
.loginForm .mainLogo{
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.ant-tabs-left >.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane, .ant-tabs-left >div>.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane{
  padding-left: 12px !important;
}
.ant-input-outlined.ant-input-password, input#login_email, .row.loginBox .col-sm-5 input {
  height: 50px;
  border-radius: 10px;
  font-family: Inter;
}
.col-sm-12.dmTabs, .container-fluid.colorListing {
  margin-bottom: 40px;
}
.row.shirtgs .col-sm-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1370px) {
  .col-sm-3.sidebarss ul li a {
    padding: 15px 20px;
  }
  .row.loginBox .col-sm-5 {
    width: 50%;
  }
  .cololrnMAe {
    font-size: 13px !important;
    padding: 0 10px;
    width: 80px;
  }
  .col-sm-3.sidebarss ul a {
    font-size: 18px;
  }
  .col-sm-10.buttonDispaly button img {
    height: 110px;
  }
  .fleexWrapColor {
    gap: 4px;
  }
  .row.boddyTab .col-sm-12 .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 10px;
  }
  tbody td {
    padding: 10px 15px !important;
  }
  button.ant-btn-primary, .tabsShort .ant-tabs-tab {
    height: 42px;
    padding: 0 30px !important;
    font-size: 14px;
    font-weight: 500;
  }
  .col-sm-12.dashBoard h2 {
    font-size: 30px;
  }
  .row.filter h4 {
    font-size: 22px;
    margin-bottom: 15px !important;
  }
  .col-sm-3.sidebarss .col-sm-12 {
    padding: 40px 0 0;
  }
  .col-sm-2.forSvg {
    padding: 0;
  }
}

@media screen and (max-width: 1280px) {
  .row.boddyTab .col-sm-12 .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 16px;
  }
  .row.boddyTab .col-sm-12 .ant-tabs-tab{
    height: 48px !important;
    padding: 12px 30px;
  }
  .fleexWrapColor {
    gap: 3px;
  }
  .row.shirtgs .col-sm-3 {
    width: 50%;
  }
}

@media only screen and (max-width: 1100px){
  .col-sm-12.dmTabs .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 16px;
  }
  .col-sm-3.svgHeader img {
    height: 200px;
    top: -80px !important;
  }
  .row.filter .ant-input-outlined, .row.filter .ant-select.ant-select-outlined {
    height: 40px;
  }
  .col-sm-3.sidebarss {
    width: 25% !important;
  } 
  .col-sm-9.ssidebar{
    width: 75% !important;
  }
  .profile input {
    height: 40px;
    border: 1px solid #6C7275 !important;
  }
  .profile label {
    font-weight: 500;
    font-size: 13px !important;
  }
  .col-sm-3.sidebarss ul li {
    margin-bottom: 15px;
  }
  .col-sm-3.sidebarss ul a {
    font-size: 16px;
  }
  .col-sm-3.sidebarss img {
    height: 50px;
  }
}

@media screen and (max-width: 820px)
{
  .row.desktttop{
    display: none !important;
  }
  .row.loginBox .col-sm-5 {
    width: 70%;
    padding: 50px 30px;
  }
  .col-sm-10.buttonDispaly{
    width: 70%;
  }
  .col-sm-2.forSvg{
    width: 30%;
  }
  .col-sm-3.sidebarss{
    width: 100% !important;
    height: auto !important;
  }
  .col-sm-9.ssidebar {
    width: 100% !important;
  }
  .row.mobile{
    display: flex !important;
    height: 80px;
    align-items: center;
  }
  .col-sm-6.butnNav button{
    background: transparent !important;
    border: unset !important;
    padding: 0;
  }
  .col-sm-6.butnNav{
    text-align: right;
    padding-right: 30px;
  }
  .col-sm-6.btnLeft{
    padding-left: 30px;
  }
  .chart-wrapper canvas {
    width: 100% !important;
    height: auto !important;
  }
  .ant-picker.ant-picker-range.ant-picker-outlined {
    width: 50%;
  }
  .row.dataBox .col-sm-3 {
    padding: 15px;
  }
  .chart-wrapper{
    flex-direction: column;
  }
  .offCanvasDiv{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .offCanvasDiv img{
    margin-top: 30px;
  }
  .offcanvas-header{
    align-items: flex-start !important;
  }
  .offcanvas-body ul li a {
    padding: 15px 20px;
    width: 100% !important;
    display: flex;
    color: #fff;
    text-decoration: none;
  }
  .col-sm-12.barchart {
    padding: 40px;
    margin-top: 20px;
    height: auto !important;
  }
  .row.dataBox {
    padding: 0 20px 20px;
  }
  .offcanvas-body ul{
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
  }
}